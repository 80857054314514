import "../styles/index.scss";

import Shuffle from "shufflejs";

function addClickListener() {
  window.addEventListener("scroll", onScroll);

  document.addEventListener(
    "click",
    function(event) {
      if (event.target.closest(".js-menu-toggle")) {
        toogleHtmlClass("has-menu");
      }
    },
    false,
  );
}

function toogleHtmlClass(className) {
  root.classList.toggle(className);
}

function onScroll() {
  const y = window.scrollY;
  if (y >= 800) {
    root.classList.add("is-scrolled");
  } else {
    root.classList.remove("is-scrolled");
  }
}

class Grid {
  constructor(element) {
    this.element = element;
    this.shuffle = new Shuffle(element, {
      itemSelector: ".js-grid-item",
      isCentered: false,
      sizer: element.querySelector(".js-grid-sizer"), // could also be a selector: '.my-sizer-element'
      filterMode: Shuffle.FilterMode.ALL,
      buffer: 1,
    });

    // Log events.
    this.addShuffleEventListeners();
    this._activeFilters = [];
    //this.addFilterButtons();
    this.addFilterSelects();
    this.addFilterChecks();
    //this.addSorting();
    //this.addSearchFilter();
  }

  /**
   * Shuffle uses the CustomEvent constructor to dispatch events. You can listen
   * for them like you normally would (with jQuery for example).
   */
  addShuffleEventListeners() {
    this.shuffle.on(Shuffle.EventType.LAYOUT, data => {
      this.updateCountes(data.shuffle.visibleItems);
    });
    this.shuffle.on(Shuffle.EventType.REMOVED, data => {
      console.log("removed. data:", data);
    });
  }

  updateCountes(to) {
    const demoClasses = document.querySelectorAll(".js-filter-count");

    // Change the text of multiple elements with a loop
    demoClasses.forEach(element => {
      element.textContent = to;
    });
  }

  addFilterButtons() {
    const options = document.querySelector(".filter-options");
    if (!options) {
      return;
    }

    const filterButtons = Array.from(options.children);
    const onClick = this._handleFilterClick.bind(this);
    filterButtons.forEach(button => {
      button.addEventListener("click", onClick, false);
    });
  }

  _handleFilterClick(evt) {
    const btn = evt.currentTarget;
    const isActive = btn.classList.contains("active");
    const btnGroup = btn.getAttribute("data-group");

    this._removeActiveClassFromChildren(btn.parentNode);

    let filterGroup;
    if (isActive) {
      btn.classList.remove("active");
      filterGroup = Shuffle.ALL_ITEMS;
    } else {
      btn.classList.add("active");
      filterGroup = btnGroup;
    }

    this.addFilter(filterGroup);
  }

  addFilterSelects() {
    const selectors = document.querySelectorAll(".js-filter-select");
    if (!selectors) {
      return;
    }

    //const onChange = this._handleFilterSelect.bind(this);
    const onChange = this._handleAllFilters.bind(this);
    selectors.forEach(select => {
      select.addEventListener("change", onChange, false);
    });
  }

  _handleFilterSelect(e) {
    const select = e.currentTarget;
    //const isActive = btn.classList.contains("active");

    var selectGroup = select.value;
    //this._removeActiveClassFromChildren(btn.parentNode);

    this.addFilter(selectGroup);
  }

  addFilterChecks() {
    const checks = document.querySelectorAll(".js-filter-check");
    if (!checks) {
      return;
    }

    //const onChange = this._handleFilterCheck.bind(this);
    const onChange = this._handleAllFilters.bind(this);

    checks.forEach(check => {
      check.addEventListener("change", onChange, false);
    });
  }

  _handleFilterCheck(e) {
    const check = e.currentTarget;
    //const isActive = btn.classList.contains("active");
    const checkGroup = check.getAttribute("data-group");
    var selectGroup = check.checked;

    if (check.checked) {
      this.addFilter(checkGroup);
    } else {
      this.removeFilter(checkGroup);
    }

    //this._removeActiveClassFromChildren(btn.parentNode);
  }

  _handleAllFilters() {
    const filters = [];

    const checks = document.querySelectorAll(".js-filter-check");
    const selectors = document.querySelectorAll(".js-filter-select");

    checks.forEach(check => {
      if (check.checked) {
        const group = check.getAttribute("data-group");
        if (group && group !== "") {
          filters.push(group);
        }
      }
    });

    selectors.forEach(select => {
      if (select.value && select.value !== "") {
        filters.push(select.value);
      }
    });

    this._activeFilters = filters;
    this.shuffle.filter(this._activeFilters);
    debugger;
  }

  addFilter(group) {
    this._activeFilters = [...this._activeFilters, group];
    this.shuffle.filter(this._activeFilters);
  }
  removeFilter(group) {
    this._activeFilters = this._activeFilters.filter(item => item !== group);
    this.shuffle.filter(this._activeFilters);
  }
  _removeActiveClassFromChildren(parent) {
    const { children } = parent;
    for (let i = children.length - 1; i >= 0; i--) {
      children[i].classList.remove("active");
    }
  }

  addSorting() {
    const buttonGroup = document.querySelector(".sort-options");
    if (!buttonGroup) {
      return;
    }
    buttonGroup.addEventListener("change", this._handleSortChange.bind(this));
  }

  _handleSortChange(evt) {
    // Add and remove `active` class from buttons.
    const buttons = Array.from(evt.currentTarget.children);
    buttons.forEach(button => {
      if (button.querySelector("input").value === evt.target.value) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });

    // Create the sort options to give to Shuffle.
    const { value } = evt.target;
    let options = {};

    function sortByDate(element) {
      return element.getAttribute("data-created");
    }

    function sortByTitle(element) {
      return element.getAttribute("data-title").toLowerCase();
    }

    if (value === "date-created") {
      options = {
        reverse: true,
        by: sortByDate,
      };
    } else if (value === "title") {
      options = {
        by: sortByTitle,
      };
    }
    this.shuffle.sort(options);
  }

  // Advanced filtering
  addSearchFilter() {
    const searchInput = document.querySelector(".js-shuffle-search");
    if (!searchInput) {
      return;
    }
    searchInput.addEventListener("keyup", this._handleSearchKeyup.bind(this));
  }

  /**
   * Filter the shuffle instance by items with a title that matches the search input.
   * @param {Event} evt Event object.
   */
  _handleSearchKeyup(evt) {
    const searchText = evt.target.value.toLowerCase();
    this.shuffle.filter((element, shuffle) => {
      // If there is a current filter applied, ignore elements that don't match it.
      if (shuffle.group !== Shuffle.ALL_ITEMS) {
        // Get the item's groups.
        const groups = JSON.parse(element.getAttribute("data-groups"));
        const isElementInCurrentGroup = groups.indexOf(shuffle.group) !== -1;
        // Only search elements in the current group
        if (!isElementInCurrentGroup) {
          return false;
        }
      }
      const titleElement = element.querySelector(".picture-item__title");
      const titleText = titleElement.textContent.toLowerCase().trim();
      return titleText.indexOf(searchText) !== -1;
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  var root = document.getElementsByTagName("html")[0];
  window.grid = new Grid(document.querySelector(".js-grid"));
  if (window.getComputedStyle(document.body).mixBlendMode === undefined) {
    root.className.remove("supports--mixBlendMode");
  }

  window.root = document.getElementsByTagName("html")[0];
  window.grid = new Grid(document.querySelector(".js-grid"));
  addClickListener();
});
